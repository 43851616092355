<script>
import { CalcTypes } from '@/utils/types'

export default {
  name: 'DropdownConfigsMixin',
  methods: {
    getOptions(data, needOrder = false) {
      const options = data
        .map((item, index) => ({
          id: item.systemId ?? item.id ?? index,
          value: item.name ?? item.title ?? item,
          ...item
        }))

      return needOrder ? options.slice().sort((a, b) => a.order - b.order) : options
    },
    getValidationRule(param, fromTooltip = false) {
      if (this.material?.toolTip && fromTooltip) {
        const [ min, max ] = this.material?.toolTip.split(' ')
          .filter(item => /[0-1]/g.test(item))
          .map(item => parseInt(item, 10))

        return { min, max }
      }

      return this.validationRules[this.isProAlgorithm ? CalcTypes.pro : CalcTypes.lite][param]
    }
  },
  computed: {
    dropdowns() {
      return [
        {
          id: 'buildingType',
          label: this.$t('message.constructionTypes.buildings.title'),
          source: this.buildingTypes,
          viewCondition: true,
          selected: this.buildingTypes?.find((item) => item.value === this.buildingType)?.value,
          disabled: false
        },
        {
          id: 'rating',
          label: this.$t('message.constructionTypes.rating.title'),
          source: this.ratings,
          viewCondition: this.isHotel,
          selected: this.sector.constructionType.rating,
          disabled: !this.buildingType
        },
        {
          id: 'location',
          label: this.$t('message.constructionTypes.location.title'),
          source: this.locations,
          viewCondition: true,
          selected: this.locations?.find((item) => item.id === this.location?.id)?.value ?? null,
          disabled: !this.buildingType
        }
      ]
    },
    additionalFloorParams() {
      return [
        {
          id: 'buildingType',
          label: this.$t('message.constructionTypes.rooms.top'),
          source: this.topRooms,
          viewCondition: true,
          selected: this.topRooms?.find((item) => item.value === this.buildingType)?.value ?? null,
          disabled: false
        },
        {
          id: 'location',
          label: this.$t('message.constructionTypes.rooms.bottom'),
          source: this.bottomRooms,
          viewCondition: true,
          selected: this.bottomRooms?.find((item) => item.id === this.location?.id)?.value ?? null,
          disabled: !this.buildingType
        }
      ]
    },
    structure() {
      return [
        {
          id: this.type,
          label: this.getLabel(),
          source: this.formattedMaterials,
          viewCondition: true,
          selected: this.title,
          disabled: false,
          validationRules: null,
          type: 'text',
          tooltip: null
        },
        {
          id: 'density',
          label: this.$t('message.constructionParams.common.density'),
          source: this.densities,
          viewCondition: this.isFacing || this.isBase,
          selected: this.selected?.density ?? null,
          editable: this.material?.isCustomDensity,
          disabled: !this.title,
          validationRules: this.getValidationRule('density', true),
          type: 'float',
          tooltip: this.material?.toolTip
        },
        {
          id: 'thickness',
          label: this.$t('message.constructionParams.common.thickness'),
          source: this.thicknesses,
          viewCondition: true,
          selected: this.selected?.thickness ?? null,
          editable: this.material?.isCustomThickness,
          disabled: !this.title,
          validationRules: this.getValidationRule('thickness', false),
          type: 'float',
          tooltip: null
        },
        {
          id: 'sheets',
          label: this.$t('message.constructionParams.common.sheets'),
          source: this.sheets,
          viewCondition: this.isFacing,
          selected: this.selected?.sheets ?? null,
          disabled: !this.title,
          validationRules: null,
          type: 'text',
          tooltip: null
        }
      ]
    }
  }
}
</script>
